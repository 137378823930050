import gsap from "../libs/gsap.min";

let images = document.images;
let loaded = 0;
let filesLength = images.length;

let loaderHTML = document.querySelector(".loader");

let percentHTML = document.querySelector(".loader__information_percent");
let progressHTML = document.querySelector(".loader__information_progress");

gsap.set(".loader__content_logo", { yPercent: 110 });
gsap.set(".loader__information_percent", { yPercent: 110 });

const getFiles = () => {
    gsap.to(".loader__content_logo", { yPercent: 0 }, "<");
    gsap.to(".loader__information_percent", { yPercent: 0 }, "<25%");
    for (let i = 0; i < filesLength; i++) {
        let tImg = new Image();
        tImg.onload = filesLoading;
        tImg.onerror = filesLoading;
        tImg.src = images[i].src;
    }
};

const filesLoading = async () => {
    loaded += 1;
    let percentage = `${((100 / filesLength) * loaded) << 0}%`;
    console.log(percentage);
    if (loaded === filesLength) doneLoading();
};

const doneLoading = () => {
    let loaderTimeLine = gsap.timeline();

    loaderTimeLine.to(progressHTML, {
        width: "100%",
        duration: 2,
        ease: Power1.easeIn,
    });
    loaderTimeLine.fromTo(
        percentHTML,
        { textContent: "0%" },
        {
            textContent: "100%",
            snap: { textContent: 1 },
            duration: 2,
            ease: Power1.easeIn,
        },
        "<"
    );

    loaderTimeLine.to(loaderHTML, {
        opacity: 0,
        ease: Power1.easeIn,
    });
    loaderTimeLine.to(loaderHTML, { zIndex: -1 });
};

gsap.set(".loader", { opacity: 1, pointerEvents: "all" });
getFiles();
